import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5373 10404 c-7 -3 -13 -16 -13 -29 0 -18 13 -31 57 -55 111 -63 205
-187 233 -309 20 -85 8 -222 -26 -299 -24 -57 -26 -65 -12 -79 23 -22 52 -9
72 35 118 245 34 545 -196 696 -54 36 -93 49 -115 40z"/>
<path d="M5288 10239 c-30 -17 -21 -47 21 -68 53 -28 123 -108 144 -167 22
-63 22 -160 -1 -221 -22 -59 -18 -83 12 -83 80 0 114 218 54 351 -29 64 -75
119 -132 158 -51 35 -76 43 -98 30z"/>
<path d="M5190 10061 c-14 -27 -15 -25 28 -54 22 -14 47 -42 57 -63 15 -33 16
-44 6 -86 -16 -59 -7 -90 22 -86 52 7 75 134 38 207 -41 80 -127 127 -151 82z"/>
<path d="M4371 10033 c-69 -137 -67 -361 6 -493 36 -64 47 -79 105 -138 96
-98 213 -144 213 -83 0 18 -14 32 -60 60 -112 68 -167 133 -212 247 -36 94
-38 230 -4 319 13 33 24 74 25 90 1 26 -3 30 -26 33 -23 3 -30 -3 -47 -35z"/>
<path d="M4557 9982 c-42 -46 -63 -162 -47 -250 18 -103 81 -195 172 -253 57
-37 92 -36 96 2 3 22 -4 31 -41 53 -133 77 -189 230 -136 371 11 30 18 64 14
75 -8 25 -37 26 -58 2z"/>
<path d="M4972 9935 c-34 -25 -55 -72 -48 -111 15 -75 120 -102 177 -45 37 38
40 97 6 136 -29 34 -100 45 -135 20z"/>
<path d="M4723 9908 c-15 -20 -33 -78 -33 -109 0 -89 119 -218 165 -179 24 20
17 48 -16 67 -59 35 -83 104 -59 169 6 15 8 35 5 45 -7 22 -47 26 -62 7z"/>
<path d="M5745 9599 c-27 -10 -129 -46 -225 -79 -285 -98 -273 -92 -299 -132
l-23 -36 -42 15 c-40 14 -43 14 -200 -41 -88 -30 -166 -62 -175 -71 -15 -14
-11 -28 35 -162 28 -81 58 -152 67 -157 18 -11 76 7 502 155 110 38 209 68
219 67 14 -2 25 -21 42 -73 12 -38 57 -169 99 -290 42 -121 107 -310 145 -420
38 -110 116 -339 175 -510 59 -170 113 -328 120 -350 12 -37 116 -339 260
-754 31 -89 55 -173 53 -184 -3 -23 -29 -33 -453 -177 -99 -34 -187 -64 -195
-67 -8 -3 -116 -40 -240 -83 -124 -43 -272 -94 -330 -114 -58 -20 -116 -35
-129 -34 -26 3 -22 -8 -142 345 -18 51 -38 101 -45 110 -14 20 10 27 -404
-116 -303 -106 -316 -110 -327 -102 -6 3 -17 26 -26 51 -150 431 -194 570
-184 578 7 6 59 26 117 46 217 73 573 196 581 202 5 3 9 13 9 23 0 28 -90 284
-105 300 -15 15 -39 8 -465 -138 -128 -45 -242 -81 -252 -81 -11 0 -23 13 -31
33 -39 94 -198 579 -194 590 3 7 40 25 83 39 44 15 185 63 314 108 129 45 251
87 270 93 26 9 36 18 38 37 1 14 -20 88 -47 165 -37 107 -53 141 -67 143 -19
3 -44 -6 -584 -191 -424 -146 -453 -156 -463 -166 -11 -11 -9 -18 118 -386 59
-170 155 -452 215 -625 60 -173 147 -427 195 -565 48 -137 95 -275 105 -305
48 -144 122 -358 132 -382 6 -16 18 -28 28 -28 15 0 355 114 637 213 55 19 74
23 82 13 9 -10 79 -208 132 -369 25 -78 37 -97 58 -97 17 0 238 72 411 134 41
15 107 38 145 51 39 12 143 48 232 79 90 31 234 81 320 110 87 30 275 95 418
144 143 49 310 107 370 127 61 21 116 43 123 48 20 16 12 51 -51 232 -31 88
-103 297 -160 465 -58 168 -142 411 -187 540 -45 129 -99 287 -120 350 -21 63
-64 187 -95 275 -31 88 -62 178 -70 200 -16 48 -180 524 -300 870 -48 138 -91
266 -97 285 -12 42 -35 70 -57 69 -9 0 -38 -9 -66 -20z"/>
<path d="M4295 9099 c-143 -50 -402 -139 -575 -199 -533 -183 -946 -327 -1048
-364 -71 -26 -112 -34 -142 -25 -10 3 -15 -4 -15 -21 0 -18 6 -26 23 -28 16
-3 22 -10 22 -28 0 -14 9 -37 20 -52 10 -15 30 -61 44 -102 13 -41 78 -232
144 -425 66 -192 172 -501 235 -685 106 -310 290 -845 393 -1140 23 -69 84
-244 134 -390 150 -434 141 -415 173 -418 15 -2 104 24 200 57 94 33 285 99
422 146 377 129 409 141 420 159 7 12 -3 53 -41 164 -49 142 -52 147 -80 150
-16 1 -112 -26 -219 -64 -478 -166 -485 -168 -499 -153 -7 8 -34 75 -59 149
-43 129 -136 399 -323 940 -47 135 -96 277 -109 315 -43 129 -253 736 -337
975 -45 129 -68 220 -56 220 2 0 130 43 284 96 153 52 290 99 304 104 14 5
117 40 230 79 394 137 532 182 543 178 6 -2 46 -104 88 -227 42 -124 83 -229
92 -234 18 -12 69 4 445 133 145 50 266 91 269 91 10 0 -9 -247 -47 -630 -11
-111 -34 -360 -50 -545 -10 -110 -22 -243 -40 -430 -5 -55 -12 -138 -16 -185
-6 -90 -6 -92 97 -378 10 -29 15 -33 38 -29 23 4 266 87 671 229 63 22 161 55
217 74 124 41 153 54 153 68 0 21 -82 263 -101 298 -5 10 -18 18 -27 18 -10 0
-103 -30 -207 -66 -407 -140 -487 -166 -494 -158 -5 5 15 266 44 579 29 314
58 631 64 705 7 74 16 171 21 215 5 44 15 143 21 220 l12 141 -38 109 c-61
177 -75 213 -88 218 -12 4 -114 -29 -497 -162 -113 -39 -211 -70 -219 -69 -8
2 -43 89 -87 215 -40 117 -78 221 -84 233 -7 13 -20 20 -38 19 -15 0 -144 -41
-287 -90z"/>
<path d="M8108 6527 c-32 -11 -58 -25 -58 -29 0 -5 27 -84 61 -176 134 -366
144 -407 118 -480 -21 -59 -116 -109 -191 -99 -77 10 -129 82 -192 264 -18 54
-40 116 -49 138 -8 22 -29 81 -47 130 -17 50 -36 94 -40 99 -8 8 -93 -18 -117
-36 -9 -7 2 -49 42 -161 128 -366 157 -431 217 -487 63 -61 101 -75 198 -75
71 0 94 4 141 26 149 70 216 209 175 366 -19 77 -188 543 -195 542 -3 0 -32
-10 -63 -22z"/>
<path d="M7125 6187 c-209 -70 -318 -262 -269 -477 34 -153 131 -288 248 -345
44 -21 77 -30 135 -33 224 -13 401 138 418 356 16 209 -92 403 -272 487 -51
24 -202 31 -260 12z m180 -128 c28 -8 58 -29 101 -73 82 -85 109 -156 109
-283 0 -86 -2 -94 -30 -134 -45 -64 -110 -100 -189 -106 -116 -10 -200 48
-268 185 -36 73 -49 186 -29 260 32 117 173 187 306 151z"/>
<path d="M7360 5950 c0 -6 16 -24 35 -41 46 -41 62 -95 46 -150 -7 -23 -10
-44 -7 -47 14 -13 30 21 34 71 5 63 -20 123 -67 156 -33 24 -41 26 -41 11z"/>
<path d="M7330 5896 c0 -8 7 -17 15 -20 24 -9 46 -70 39 -105 -4 -18 -2 -31 4
-31 26 0 30 79 5 120 -19 33 -63 58 -63 36z"/>
<path d="M7308 5854 c-4 -3 -1 -16 7 -28 8 -11 15 -31 15 -43 0 -13 5 -23 10
-23 17 0 11 56 -8 80 -9 12 -20 18 -24 14z"/>
<path d="M7036 5828 c-22 -84 9 -174 71 -210 48 -28 45 -11 -8 42 -44 44 -49
54 -49 94 0 25 4 56 10 70 7 20 6 26 -4 26 -8 0 -16 -10 -20 -22z"/>
<path d="M7090 5804 c-7 -16 -9 -42 -6 -59 7 -32 52 -85 73 -85 19 0 17 5 -18
39 -26 27 -30 37 -28 76 4 54 -7 68 -21 29z"/>
<path d="M7240 5812 c-23 -8 -30 -24 -19 -45 14 -25 53 -19 57 9 3 24 -17 44
-38 36z"/>
<path d="M7146 5785 c-8 -21 10 -64 29 -71 24 -10 30 4 12 24 -10 10 -17 28
-17 40 0 25 -15 29 -24 7z"/>
<path d="M6636 6001 c-6 -9 -86 -414 -86 -433 0 -5 -3 -8 -7 -8 -9 0 -211 160
-319 253 -17 15 -36 27 -42 27 -12 0 -82 -84 -82 -99 0 -10 197 -172 356 -294
49 -37 66 -58 80 -95 9 -26 32 -85 49 -132 18 -47 36 -95 40 -108 4 -13 12
-21 18 -19 7 2 36 13 65 23 28 10 52 23 52 29 0 5 -23 74 -52 153 l-51 144 16
86 c9 48 32 166 52 262 19 96 35 180 35 186 0 6 -21 15 -46 19 -26 3 -52 9
-59 11 -7 3 -16 1 -19 -5z"/>
<path d="M5810 5699 c-44 -13 -249 -100 -255 -109 -3 -6 11 -49 94 -275 10
-27 50 -138 88 -245 39 -107 77 -212 86 -232 l16 -38 63 25 c39 14 62 29 60
37 -2 7 -23 69 -48 137 -24 68 -44 127 -44 131 0 4 26 17 58 28 152 56 246
154 258 271 12 114 -54 228 -152 264 -54 19 -165 22 -224 6z m178 -136 c61
-38 80 -132 40 -194 -25 -37 -133 -98 -181 -102 -27 -2 -30 2 -73 117 -24 66
-44 127 -44 136 0 9 19 22 53 34 102 37 157 40 205 9z"/>
<path d="M5107 5449 c-69 -16 -151 -62 -200 -111 -67 -67 -99 -142 -105 -243
-6 -104 10 -177 59 -275 85 -170 257 -265 422 -231 134 27 239 105 291 216 22
46 30 79 34 145 6 105 -9 180 -53 268 -91 179 -272 273 -448 231z m181 -148
c54 -28 128 -114 154 -181 13 -34 23 -87 26 -141 4 -74 1 -91 -18 -130 -62
-121 -211 -169 -328 -104 -176 96 -241 385 -114 507 83 80 185 98 280 49z"/>
<path d="M4610 5254 c-36 -13 -166 -61 -290 -105 -124 -45 -228 -84 -232 -88
-8 -7 17 -85 34 -108 7 -8 38 -1 119 28 60 21 111 39 113 39 6 0 34 -75 137
-360 56 -157 106 -290 111 -297 7 -8 24 -5 66 12 31 13 58 27 60 33 1 5 -10
45 -27 89 -16 43 -47 130 -70 193 -22 63 -61 171 -86 239 -25 68 -45 128 -45
135 0 6 50 29 110 51 94 34 110 43 110 62 0 12 -4 24 -9 28 -5 3 -12 19 -16
35 -4 16 -10 32 -13 34 -4 2 -36 -7 -72 -20z"/>
<path d="M0 5038 c0 -39 4 -68 10 -68 6 0 10 -8 10 -18 0 -10 19 -47 43 -83
43 -64 102 -177 99 -191 -1 -5 2 -8 8 -8 11 0 50 -73 50 -95 0 -8 4 -15 9 -15
5 0 13 -13 19 -28 5 -16 16 -35 24 -43 7 -8 24 -39 37 -69 13 -30 34 -66 47
-80 13 -14 28 -49 34 -78 7 -32 19 -56 30 -62 17 -9 80 -113 80 -133 0 -5 8
-21 19 -35 10 -15 22 -38 26 -52 4 -14 14 -33 23 -42 8 -10 11 -18 7 -18 -5 0
7 -17 27 -37 19 -21 38 -50 41 -64 12 -48 48 -119 62 -119 7 0 17 -15 20 -32
4 -18 13 -40 19 -48 7 -8 25 -40 41 -70 15 -30 36 -66 45 -80 10 -14 30 -53
45 -87 15 -35 31 -63 36 -63 5 0 13 -17 18 -37 6 -21 22 -50 35 -64 14 -15 28
-44 32 -64 4 -21 14 -45 23 -55 26 -27 72 -113 131 -241 11 -26 29 -54 40 -64
11 -10 20 -23 20 -29 -1 -20 95 -206 106 -206 11 0 38 -56 48 -100 4 -21 32
-61 97 -138 6 -7 13 -29 16 -50 3 -22 17 -49 34 -66 16 -15 29 -34 29 -41 0
-6 13 -30 29 -52 16 -23 35 -59 42 -82 7 -23 16 -41 20 -41 5 0 13 -19 20 -42
6 -24 20 -51 30 -61 10 -10 19 -30 19 -45 0 -16 8 -32 20 -40 11 -7 20 -19 20
-27 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -11 6 -20 14 -20 15 0 26 -21 26
-51 0 -31 61 -133 97 -162 13 -10 23 -24 23 -31 0 -17 29 -74 41 -81 5 -4 9
-17 9 -30 0 -13 6 -28 14 -32 7 -4 22 -28 33 -53 11 -25 30 -55 41 -66 14 -14
19 -29 15 -43 -4 -15 4 -34 24 -59 17 -21 45 -64 62 -96 17 -32 31 -55 31 -50
0 17 20 -21 20 -39 0 -10 10 -31 22 -47 12 -16 19 -32 16 -35 -3 -3 10 -21 28
-39 20 -20 34 -44 34 -58 0 -32 27 -68 51 -68 11 0 19 -7 20 -17 3 -44 17 -73
35 -73 13 0 18 -12 15 -32 -2 -12 17 -10 24 2 6 10 -12 74 -26 95 -4 6 -34 62
-67 125 -33 63 -88 167 -122 230 -35 63 -77 142 -95 175 -59 111 -108 200
-115 210 -4 6 -27 46 -50 90 -52 97 -151 278 -196 360 -18 33 -43 80 -56 105
-13 25 -25 47 -29 50 -3 3 -18 30 -33 60 -51 98 -93 176 -108 201 -9 13 -35
60 -58 104 -24 44 -57 105 -73 135 -17 30 -45 80 -61 112 -17 31 -43 78 -57
105 -15 26 -40 73 -55 103 -16 30 -32 57 -36 60 -3 3 -31 52 -60 110 -30 58
-69 129 -86 158 -18 29 -32 59 -32 67 0 8 -4 16 -9 20 -6 3 -16 18 -24 33 -33
64 -99 186 -169 312 -41 74 -94 171 -118 215 -23 44 -46 85 -50 90 -4 6 -31
55 -61 110 -29 55 -63 118 -75 140 -13 22 -31 56 -41 75 -10 19 -35 67 -56
105 -22 39 -55 99 -74 135 -20 36 -47 85 -61 110 -33 58 -60 108 -109 200 -91
170 -117 217 -140 252 -12 21 -23 41 -23 46 0 4 -7 17 -15 28 -13 17 -14 13
-15 -48z"/>
<path d="M3975 5025 c-45 -21 -443 -165 -456 -165 -14 0 -7 -42 15 -94 l15
-36 63 24 c161 60 299 108 303 104 2 -2 -25 -116 -61 -253 -35 -138 -76 -298
-90 -356 l-27 -105 21 -47 21 -46 68 25 c37 14 97 35 133 48 36 13 117 43 180
66 63 23 130 46 148 51 17 6 32 13 32 16 0 28 -36 113 -48 113 -8 0 -98 -30
-199 -66 -101 -37 -186 -64 -190 -60 -3 3 29 142 72 308 104 404 101 386 85
442 -14 51 -29 57 -85 31z"/>
<path d="M3180 4735 c-124 -46 -229 -85 -234 -87 -9 -4 -6 -14 164 -478 61
-168 114 -308 118 -312 4 -3 48 9 97 27 50 18 155 57 235 86 80 29 147 54 149
56 2 2 -4 28 -14 58 -13 36 -25 55 -35 55 -9 0 -88 -27 -175 -59 -88 -33 -163
-56 -167 -52 -4 3 -25 54 -47 111 -29 78 -36 108 -27 116 6 6 72 33 146 59 74
27 137 50 139 52 9 7 -32 113 -43 113 -7 0 -73 -22 -149 -50 -76 -27 -141 -50
-146 -50 -8 0 -71 167 -71 187 0 5 79 37 175 73 140 51 174 67 169 80 -3 8
-13 34 -21 58 -8 23 -20 42 -26 41 -7 -1 -113 -39 -237 -84z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
